import firebase from 'firebase/compat/app';
import type { FirebaseOptions } from 'firebase/app';

export { loadFirebaseConfig, setupFirebaseAuth };

function loadFirebaseConfig() {
  try {
    // Your web app's Firebase configuration
    const { default: firebaseConfig } = require('./firebase-config');
    return firebaseConfig;
  } catch (err) {
    console.warn('Could not load firebase config', err);
    return {
      apiKey: '',
      authDomain: '',
      projectId: '',
      storageBucket: '',
      messagingSenderId: '',
      appId: '',
    };
  }
}

function setupFirebaseAuth(firebaseConfig: FirebaseOptions) {
  if (!firebaseConfig.apiKey) {
    throw new Error(
      'Invalid api key. Be sure you followed "client-simulator" README'
    );
  }

  const app = firebase.initializeApp(firebaseConfig);
  return { firebaseAuth: firebase.auth(app) };
}
