export {
  sendVerificationCodeQuery,
  signInQuery,
  signInWithProviderQuery,
  signUpQuery,
  signUpWithProviderQuery,
};

type PhoneNumber = { countryCode: string; nationalNumber: string };

function sendVerificationCodeQuery({
  countryCode,
  nationalNumber,
}: PhoneNumber) {
  return `
    mutation {
      sendVerificationCode(
        input: {
          phoneNumber: {
            countryCode: "${countryCode}",
            nationalNumber: "${nationalNumber}"
          }
        }
      ) {
        retryAfterSeconds
      }
    }
  `;
}

function signInQuery({
  verificationCode,
  phone,
}: {
  verificationCode: string;
  phone: PhoneNumber;
}) {
  return `
    mutation {
      signIn(
        input: {
          verificationCode: "${verificationCode}",
          phoneNumber: {
            countryCode: "${phone.countryCode}",
            nationalNumber: "${phone.nationalNumber}"
          }
        }
      ) {
        customToken
      }
    }
  `;
}

function signInWithProviderQuery({
  verificationCode,
  phone,
  providerAccountToken,
}: {
  verificationCode: string;
  phone: PhoneNumber;
  providerAccountToken: string;
}) {
  return `
    mutation {
      signIn: signInWithProvider(
        input: {
          verificationCode: "${verificationCode}",
          phoneNumber: {
            countryCode: "${phone.countryCode}",
            nationalNumber: "${phone.nationalNumber}"
          }
          providerAccountToken: "${providerAccountToken}"
        }
      ) {
        customToken
      }
    }
  `;
}

function signUpQuery({
  verificationCode,
  phone,
  firstName,
  birthDate,
  email,
}: {
  verificationCode: string;
  phone: PhoneNumber;
  firstName?: string;
  birthDate?: string;
  email?: string;
}) {
  return `
    mutation {
      signUp(
        input: {
          verificationCode: "${verificationCode}",
          phoneNumber: {
            countryCode: "${phone.countryCode}",
            nationalNumber: "${phone.nationalNumber}"
          },
          firstName: "${firstName ?? "John"}",
          birthDate: "${birthDate ?? "1970-01-01"}",
          email: "${email ?? "example@test.com"}"
        }
      ) {
        customToken
      }
    }
  `;
}

function signUpWithProviderQuery({
  verificationCode,
  phone,
  providerAccountToken,
  firstName,
  birthDate,
  email,
}: {
  verificationCode: string;
  phone: PhoneNumber;
  providerAccountToken: string;
  firstName?: string;
  birthDate?: string;
  email?: string;
}) {
  return `
    mutation {
      signUp: signUpWithProvider(
        input: {
          verificationCode: "${verificationCode}",
          phoneNumber: {
            countryCode: "${phone.countryCode}",
            nationalNumber: "${phone.nationalNumber}"
          },
          providerAccountToken: "${providerAccountToken}",
          firstName: "${firstName ?? "John"}",
          birthDate: "${birthDate ?? "1970-01-01"}",
          email: "${email ?? 'example@test.com'}"
        }
      ) {
        customToken
      }
    }
  `;
}
