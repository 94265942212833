import './App.css';
import React, { useEffect, useState } from 'react';

import './index.css';
import 'firebaseui/dist/firebaseui.css';

import { Summary } from './Summary';
import { PhoneSignIn } from './PhoneSignIn';
import { AuthResult, OAuthUIFactory } from './AuthUI';
import { loadFirebaseConfig, setupFirebaseAuth } from './firebaseSetupHelpers';

export enum AuthView {
  HOME = 'home',
  PHONE_CONFIRMATION = 'phone_confirmation',
  SUMMARY = 'summary',
}

const firebaseConfig = loadFirebaseConfig();
const { firebaseAuth } = setupFirebaseAuth(firebaseConfig);

const OAuthUI = OAuthUIFactory({ firebaseAuth });

export function App() {
  const [error, setError] = React.useState<string>();

  function retryOnError() {
    // not important
    window.location.reload();
  }

  return (
    <div className="App">
      <header className="App-header">
        {error ? (
          <div>
            <strong>{error}</strong>
            <button type="button" className="large-btn" onClick={retryOnError}>
              Retry
            </button>
          </div>
        ) : (
          <div>
            <AppView setError={setError} />
          </div>
        )}
      </header>
    </div>
  );
}

function AppView({ setError }: { setError: (error: string) => void }) {
  const [idToken, setIdToken] = React.useState<string>();
  const [providerAccountToken, setProviderAccountToken] =
    React.useState<string>();
  const [authView, setAuthView] = useState(AuthView.HOME);

  const onPhoneSignInSuccess = async (customToken: string) => {
    const userCredentials =
      await firebaseAuth.signInWithCustomToken(customToken);

    if (userCredentials.user) {
      const userTokenId = await userCredentials.user.getIdToken();

      setIdToken(userTokenId);
      setAuthView(AuthView.SUMMARY);
    }
  };

  const signInAnonymously = async () => {
    const anonymous = await firebaseAuth.signInAnonymously();
    const userTokenId = await anonymous.user?.getIdToken();

    setIdToken(userTokenId);
  };

  const onOAuthSignInSuccess = async (authResult: AuthResult) => {
    const haveConfirmedPhoneNumber = authResult.user.providerData.some(
      (info) => info.providerId === 'phone' && Boolean(info.phoneNumber)
    );

    if (haveConfirmedPhoneNumber || authResult.user.isAnonymous) {
      const userTokenId = await authResult.user.getIdToken();

      setIdToken(userTokenId);
      setAuthView(AuthView.SUMMARY);
    } else {
      await signInAnonymously();
      setProviderAccountToken(await authResult.user.getIdToken());
      setAuthView(AuthView.PHONE_CONFIRMATION);
    }
  };

  switch (authView) {
    case AuthView.HOME:
      return (
        <>
          <button
            className="sign-in-btn"
            onClick={async () => {
              await signInAnonymously();
              setAuthView(AuthView.PHONE_CONFIRMATION);
            }}
          >
            Sign in with phone
          </button>
          <OAuthUI
            onError={setError}
            onProviderSignInSuccess={onOAuthSignInSuccess}
          />
        </>
      );
    case AuthView.PHONE_CONFIRMATION:
      return (
        <PhoneSignIn
          onSuccess={onPhoneSignInSuccess}
          idToken={idToken}
          providerAccountToken={providerAccountToken}
        />
      );
    case AuthView.SUMMARY:
      return <Summary token={idToken || ''} />;
  }
}
