import React from 'react';

export function Summary({ token }: { token: string }) {
  const [copied, setCopied] = React.useState(false);

  return (
    <div>
      <div>
        <input type="text" value={token} disabled={true} />
        <button
          className="large-btn"
          disabled={copied}
          onClick={async () => {
            await navigator.clipboard.writeText(token);
            setCopied(true);
            setTimeout(() => setCopied(false), 10_000);
          }}
        >
          Copy
        </button>
      </div>
      <a
        href={`https://jwt.io/?token=${token}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="JWT.IO"
          width="120"
          height="60"
          src="https://jwt.io/img/logo-asset.svg"
        />
      </a>
    </div>
  );
}
