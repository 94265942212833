import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import { User as FirebaseUser, AuthCredential } from 'firebase/auth';

import React from 'react';

export interface AuthResult {
  user: FirebaseUser;
  credential: AuthCredential;
}

export function OAuthUIFactory({
  firebaseAuth,
}: {
  firebaseAuth: firebase.auth.Auth;
}) {
  const firebaseUi = new firebaseui.auth.AuthUI(firebaseAuth);

  let isFirebaseUiStarted = false;

  return function OAuthUI({
    onError,
    onProviderSignInSuccess,
  }: {
    onError: (error: string) => void;
    onProviderSignInSuccess: (authResult: AuthResult) => void;
  }) {
    const firebaseContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      if (!firebaseContainerRef.current) {
        console.warn('HTML container not ready');
        return;
      }

      // we can not run firebaseui few times, because they internal
      // implementation. but `useEffect` run at least 2 times in debug-mode
      !isFirebaseUiStarted &&
        firebaseUi.start(firebaseContainerRef.current, {
          signInFlow: 'popup',
          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            'apple.com',
            'anonymous',
          ],
          callbacks: {
            signInFailure(err) {
              onError(err.message);
            },
            signInSuccessWithAuthResult: onFirstAuthStepSuccess,
          },
        });
      isFirebaseUiStarted = true;

      function onFirstAuthStepSuccess(authResult: AuthResult) {
        onProviderSignInSuccess(authResult);

        return false;
      }

      return () => {
        if (!firebaseUi.isPendingRedirect()) {
          firebaseUi.reset();
        }
      };
    }, []);

    return <div ref={firebaseContainerRef} />;
  };
}
