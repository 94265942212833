import React from 'react';

import { serializePhoneNumber } from './phoneSerializer';
import {
  sendVerificationCodeQuery,
  signInQuery,
  signInWithProviderQuery,
  signUpQuery,
  signUpWithProviderQuery,
} from './gqlQueries';

const GATEWAY_API_URL =
  process.env.REACT_APP_GATEWAY_API_URL || 'http://localhost:8080';

export function PhoneSignIn({
  onSuccess,
  idToken,
  providerAccountToken,
}: {
  onSuccess: (customToken: string) => void;
  idToken: string | undefined;
  providerAccountToken?: string;
}) {
  const [phoneNumber, setPhoneNumber] = React.useState('+48');
  const [code, setCode] = React.useState('');
  const [phoneSubmitted, setPhoneSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onPhoneSubmit = async () => {
    try {
      setLoading(true);
      console.log(`Connecting to '${GATEWAY_API_URL}' API...`);

      const query = sendVerificationCodeQuery(
        serializePhoneNumber({ phoneNumber })
      );
      const codeSentResult = await graphQlRequest(query, idToken);

      if (codeSentResult.status === 200) {
        setPhoneSubmitted(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const onCodeSubmit = async () => {
    try {
      setLoading(true);

      const query = providerAccountToken
        ? signInWithProviderQuery({
            verificationCode: code,
            phone: serializePhoneNumber({ phoneNumber }),
            providerAccountToken,
          })
        : signInQuery({
            verificationCode: code,
            phone: serializePhoneNumber({ phoneNumber }),
          });
      const confirmSignInResult = await graphQlRequest(query, idToken);

      if (confirmSignInResult.status === 200) {
        const {
          data: {
            signIn: { customToken },
          },
        } = await confirmSignInResult.json();

        onSuccess(customToken);
      }

      if (confirmSignInResult.status === 404) {
        await signUp();
      }
    } finally {
      setLoading(false);
    }
  };

  const signUp = async () => {
    const query = providerAccountToken
      ? signUpWithProviderQuery({
          verificationCode: code,
          phone: serializePhoneNumber({ phoneNumber }),
          providerAccountToken,
        })
      : signUpQuery({
          verificationCode: code,
          phone: serializePhoneNumber({ phoneNumber }),
        });

    const confirmSignUpResult = await graphQlRequest(query, idToken);

    const {
      data: {
        signUp: { customToken },
      },
    } = await confirmSignUpResult.json();

    onSuccess(customToken);
  };

  return (
    <>
      {phoneSubmitted ? (
        <div className="container">
          <label>Sms code</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button
            className="sign-in-btn"
            onClick={onCodeSubmit}
            disabled={loading}
          >
            Verify
          </button>
        </div>
      ) : (
        <div className="container">
          <label>Phone number</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <button
            className="sign-in-btn"
            onClick={onPhoneSubmit}
            disabled={loading}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
}

function graphQlRequest(query: string, idToken: string | undefined) {
  return fetch(`${GATEWAY_API_URL}/`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-csrf-protected': 'yes',
      ...(idToken && { authorization: `Bearer ${idToken}` }),
    },
    body: JSON.stringify({ query }),
  });
}
