import { PhoneNumberUtil } from 'google-libphonenumber';

export { serializePhoneNumber };

function serializePhoneNumber({ phoneNumber }: { phoneNumber: string }) {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const phoneUtilNumber = phoneUtil.parse(phoneNumber);
  const countryCode = phoneUtilNumber.getCountryCode();
  const nationalNumber = phoneUtilNumber.getNationalNumber();

  if (countryCode && nationalNumber) {
    return {
      countryCode: countryCode.toString(),
      nationalNumber: nationalNumber.toString(),
    };
  }

  throw new Error('Missing country code or national number in given phone number.')
}
